import * as React from 'react';
// import { StaticImage } from "gatsby-plugin-image"
import {Link} from 'gatsby';
import styled from '@emotion/styled';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper';
import BgImage
  from '../../images/bg/thornhill-plumbing-Residential-Commercial.jpg';
import StyledLink from '../../components/styledLink';

const SubHeading = styled.h2`
  text-align: center;
  margin-top: 64px;
  margin-bottom: 32px;
`;
const FakeHeading = styled.p`
  font-size: 2.2rem;
  text-align: center;
`;

const HeroImage = styled.div`
  width: 100%;
  // height: 600px;
  overflow: hidden;
  background: url(${BgImage}) no-repeat center center;
  background-size: cover;
  margin-bottom: 64px;
`;
const HeroImageInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  color: white;
  background-color: rgba(0, 0, 0, .5);
  padding: 4em 0;
`;

const ResidentialServices = () => (
    <Layout>
		<Seo title='Thornhill Plumbing'
         description='Residential plumbing services and residential renovations' />

        <HeroImage>
            <HeroImageInnerContainer>
                <h1>Residential Plumbing Services</h1>
                <FakeHeading>
                    Trust Thornhill Plumbing &amp; Renovations for your next plumbing project.<br />
                </FakeHeading>

                <Link
                    to='/contact/'
                    title='contact thornhill plumbing and renovations'>
                    <StyledLink cta='Free Estimate' />
                </Link>
            </HeroImageInnerContainer>
        </HeroImage>

		<SiteWidthWrapper>
        <SubHeading>What makes Thornhill Plumbing your best choice for your next kitchen or bathroom renovation project?</SubHeading>
        <p>Plumbers are not exactly hard to find. So, when someone asks us why us, we respond with this: We are a family run business with over 20 years of experience behind us. But we’re not just plumbing experts. We are renovation experts too.</p>
        <p>Which means that when we take something apart, we know how to put it back together again properly.</p>
        <SubHeading>Residential &amp; commercial plumbing for any space you have or plan to build later.</SubHeading>
        <p>We have extensive experience with many different types of plumbing projects, residential and commercial.  Whether you’re re-piping your space, renovating an existing one, or adding an entirely new room to your space, we know how to get it done right.</p>
        <SubHeading>What you can expect from us when you book an appointment.</SubHeading>
        <p>We pride ourselves on making sure your project turns out exactly how you want it to. This is due to the time we take to discuss your project options.</p>
        <p>Here’s what happens:</p>
        <ul>
            <li>You contact us for a free estimate.</li>
            <li>We book a time to come out and take a look at your space.</li>
            <li>We discuss your project goals, budget, and address any concerns you might have.</li>
            <li>We get to work.</li>
            <li>You’ll relax.</li>
        </ul>
		</SiteWidthWrapper>
	</Layout>
);

export default ResidentialServices;
